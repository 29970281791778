import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import defaultTestData from '../TestData/testData.json';
import './MCTestPreview.css';

function MCTestPreview() {
    const navigate = useNavigate();
    const reduxTestData = useSelector(state => state.test.testData);
    console.log("checking test data");
    console.log({reduxTestData});
    const questions = reduxTestData ? reduxTestData.questions : defaultTestData.questions;

    const handleAcceptTest = () => {
        console.log("Test Accepted");
        navigate('/multiple-choice-test/take');
    };

    return (
        <div className="page">
            <div className="test-preview">
                <h2>Test Preview</h2>
                {questions.map((question, index) => (
                    <div key={index}>
                        <h3>Question {index + 1}</h3>
                        <p>{question.questionText}</p>
                        {question.options.map((option, idx) => (
                            <div key={idx}>
                                {option.option}: {option.text}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="test-select-options-container">
                <button onClick={handleAcceptTest}>Accept Test</button>
            </div>
        </div>
    );
}

export default MCTestPreview;