import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import defaultTestData from '../TestData/testData.json';
import './MCTestPage.css';

function MCTestPage() {
  const reduxTestData = useSelector(state => state.test.testData);
  const questionsData = reduxTestData ? reduxTestData.questions : defaultTestData.questions;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [answerSelected, setAnswerSelected] = useState(null);
  const [result, setResult] = useState(null);

  function selectAnswer(option) {
    let correct = option.option === questionsData[activeQuestionIndex].correctAnswer;
    setAnswerSelected(option);
    setResult(correct);
  }

  function previousQuestion() {
    if (activeQuestionIndex > 0) {
      setActiveQuestionIndex(activeQuestionIndex - 1);
      setAnswerSelected(null);
      setResult(null);
    }
  }

  function nextQuestion() {
    if (activeQuestionIndex < questionsData.length - 1) {
      setActiveQuestionIndex(activeQuestionIndex + 1);
      setAnswerSelected(null);
      setResult(null);
    }
  }

  if (activeQuestionIndex >= questionsData.length) {
    return <div>All questions completed!</div>;
  }

  const activeQuestion = questionsData[activeQuestionIndex];

  return (
    <div>
      <div className="question-card">
        <div className="question">
          ({activeQuestionIndex+1}/{questionsData.length}) {activeQuestion.questionText}
        </div>

        {activeQuestion.options.map((option, index) => (
          <div
            key={index}
            className={`answer ${answerSelected && answerSelected.option === option.option ? 'selected' : ''}`}
            onClick={() => selectAnswer(option)}
          >
            {option.text}
          </div>
        ))}

        {result !== null && (
          <div className="answer-description">
            Your answer is {result ? <span className="correct">correct</span> : <span className="incorrect">incorrect</span>}
            {answerSelected &&
              <div>
                {answerSelected.description}
              </div>
            }

            {result &&
              <div className="choice-description">
                {activeQuestion.answerDescription}
              </div>
            }
          </div>
        )}

        <div className="controls">
          {activeQuestionIndex > 0 && (
            <button onClick={previousQuestion}>Previous Question</button>
          )}
          {activeQuestionIndex < questionsData.length - 1 && (
            <button onClick={nextQuestion}>Next Question</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default MCTestPage;