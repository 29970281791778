// Reducers/TestReducer.js
import { SET_TEST_DATA } from '../Actions/TestActions';

const initialState = {
  testData: null,
};

const testReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEST_DATA:
      return { ...state, testData: action.payload };
    default:
      return state;
  }
};

export default testReducer;