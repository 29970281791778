// store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit'; // Import from Redux Toolkit
import testReducer from './Reducers/testReducer';

const rootReducer = combineReducers({
  test: testReducer,
});

const store = configureStore({
  reducer: rootReducer, // Pass rootReducer inside an object
});

export default store;