// Description
// App.js contains the dynamic menu and router for the entire app.

import "./App.css";
import React from "react";
import { Link, Route, Routes } from "react-router-dom";

import Home from "./Home/Home";
import MCMenuPage from "./MCTestMenuPage/MCMenuPage";
import MCTestPreview from "./MCTestPreview/MCTestPreview";
import MCTestPage from "./MCTestPage/MCTestPage";
import Contact from "./Contact/Contact";

function App() {
  return (
    <div className="app">
      <div className="menu-container">
        <nav className="menu">
          <ul>
            <Link to="/" className="list">Home</Link>
          </ul>
          <ul className="test-dropdown">
            <span className="list">Test</span>
            <div className="dropdown-content">
              <Link to="/multiple-choice-test">Multiple Choice</Link>
              <Link to="/flashcards">Flash Cards</Link>
            </div>
          </ul>
          <ul>
            <Link to="/contact" className="list">Contact</Link>
          </ul>
          <ul>
            <Link to="/login" className="list">Login/Register</Link>
          </ul>
        </nav>
      </div>

      {/* Defining routes path and rendering components as element */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/multiple-choice-test" element={<MCMenuPage />} />
        <Route path="/multiple-choice-test/preview" element={<MCTestPreview />} />
        <Route path="/multiple-choice-test/take" element={<MCTestPage />} />
        <Route path="/mctest" element={<MCTestPage />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;