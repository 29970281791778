import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setTestData } from '../Actions/TestActions';
import { useDispatch } from 'react-redux';

import "./MCMenuPage.css"
import { getByDisplayValue } from '@testing-library/dom';

function TestInputPage() {
    const [testJson, setTestJson] = useState('');
    const [fileData, setFileData] = useState('');
    const [description, setDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status

    let navigate = useNavigate();
    let dispatch = useDispatch();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const jsonData = JSON.parse(e.target.result);
                setTestData(jsonData);
            };
            reader.readAsText(file);
        }
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable submit button

        // const apiUrl = 'https://localhost:44335/MockMCTestData';
        // const apiUrl = 'https://localhost:44335/MCTestData'
        const apiUrl = 'https://api.quizcraftai.com/mctestdata'
    
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(description)
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            // Handle the response here. Adjust as per the actual response format.
            console.log(response);
            const responseData = await response.json();  // Use .json() if the response is in JSON format
            console.log('Response:', responseData);
    
            // Navigate after successful submission or handle the response as needed
            dispatch(setTestData(responseData));
            navigate('/multiple-choice-test/preview');
        } catch (error) {
            console.error('Error during data submission:', error);
            // Handle errors in submission here
        } finally {
            setIsSubmitting(false); // Re-enable submit button
        }
    };

    const handleUploadSubmit = (e) => {
        dispatch(setTestData(fileData)); // Dispatch the action
        navigate('/multiple-choice-test/preview');
    }

    return (
        <div className="mc-menu-page">
            
            {isSubmitting && (
                <div className="overlay">
                    <div className="loading-message">Generating Multiple Choice Test...</div>
                </div>
            )}

            <div className="mc-menu">
                <h2>Provide Us A Test Topic</h2>
                <form onSubmit={handleSubmit}>
                    <div className="test-option">
                        <label htmlFor="description">Give us a description of the multiple choice test you want to take:</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={handleDescriptionChange}
                            rows="5"
                            cols="50"
                        />
                    </div>
                    <div className="button-container">
                    <button type="submit" disabled={isSubmitting}>Submit</button> {/* Button disabled during submission */}
                    </div>
                </form>
                <hr />
                <span>OR</span>
                <form onSubmit={handleUploadSubmit}>
                    <div className="test-option">
                        <label htmlFor="testFile">Upload json test File:</label>
                        <input
                            type="file"
                            id="testFile"
                            accept=".json"
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="button-container">
                        <button type="submit">Submit</button>
                    </div>
                </form>
                <hr />
            </div>
        </div>
    );
}

export default TestInputPage;
